<!--suppress JSUnresolvedVariable -->
<template>
    <div id="app" class="data">
        <v-app id="inspire" dark>
            <div>
                <v-alert :value="showAlert" type="info">{{ alertCopy }}</v-alert>
            </div>
            <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-container grid-list-md>
                <v-layout row wrap>
                    <!-- START Truck Registration Form -->
                    <v-flex xs12 lg3></v-flex>
                    <v-flex xs12 lg6>
                        <v-form ref="userForm" v-model="truckValid" lazy-validation>
                            <v-toolbar-title>Truck Registration</v-toolbar-title>
                            <v-select v-model="customer" :items="companyItems" label="Select Company"></v-select>
                            <v-text-field v-model="truckName" :rules="truckRules" label="Truck Number" required></v-text-field>
                            <v-text-field v-model="serialNo" :rules="sernoRules" label="Unit Serial #" required></v-text-field>
                            <v-text-field v-model="vin" :rules="vinRules" label="VIN" required></v-text-field>
                            <v-col cols="12" class="d-flex justify-center align-center">
                                <v-btn :disabled="!truckValid" color="success" class="mr-4" @click="truckSubmit()">
                                    Submit
                                </v-btn>
                                <v-btn color="error" class="mr-4" @click="resetForm()">
                                    Reset
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-flex>
                    <v-flex xs12 lg3></v-flex>
                    <!-- END Truck Registration Form -->
                </v-layout>
                <!-- This is for the Truck / IEDACU List Display -->
                <v-layout row wrap>
                    <v-flex lg12>
                        <v-data-table :headers="truckHeaders" :items="iedacuList" :loading="tblLoader" loading-text="Loading... Please wait" :items-per-page="25" class="elevation-1"></v-data-table>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-app>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
    name: 'DataView',
    props: {
        msg: String
    },
    components: {},
    data: function() {
        return {
            truckHeaders: [
                { text: 'Index', value: 'id', sortable: false },
                { text: 'Serial #', value: 'serno', sortable: false },
                { text: 'Truck', value: 'truck_name', sortable: false },
                { text: 'VIN #', value: 'vin', sortable: false },
                { text: 'IEDACU', value: 'iedacu_id', sortable: false },
                { text: 'Schema ID', value: 'schema_id', sortable: false }
            ],
            tblLoader: true,
            truckValid: true,
            truckName: '',
            serialNo: '',
            vin: null,
            truckRules: [
                v => !!v || 'Number is required',
                v => (v && v.length > 2 && v.length < 11) || 'Truck No. must bebetween 2 and 10 characters.'
            ],
            sernoRules: [v => !!v || 'Serial # is required', v => (v && v.length === 10) || 'Serial must be 10 characters'],
            vinRules: [v => !!v || 'VIN# is required', v => (v && v.length === 17) || 'VIN must be 17 characters'],
            schema: '',
            absolute: false,
            opacity: 0.85,
            overlay: false,
            showAlert: false,
            companyList: [],
            companyItems: [],
            iedacuList: [],
            customer: '',
            alertCopy: 'Currently showing no data...',
            queryPath: process.env.VUE_APP_API_PATH + '/registration/',
            queryNHTSA: 'https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/',
            lorem: 'Lorem ipsum dolor sit amet, mel at clita quando.'
        };
    },
    created() {
        this.$vuetify.theme.dark = true;
    },
    watch: {
        iedacuList: function (val) {
            if (val.length > 0) {
                this.tblLoader = false;
            } else {
                this.tblLoader = true;
            }
        },
        truckValid: function() {
            const vm = this;
            if (!vm.truckName || !vm.serialNo || !vm.vin || !vm.customer) {
                vm.truckValid = false;
            }
        },
        vin: async function(numVal) {
            const vm = this;
            // NHTSA query string to check VIN's
            const query = `${vm.queryNHTSA}${numVal}?format=json`;
            if (numVal.length === 17) {
                vm.progressShow();
                await axios
                    .get(query, vm.authConfig)
                    .then(response => {
                        if (!vm.isNullOrUndefined(response.data)) {
                            let data = response.data;
                            let errorCode = parseInt(data.Results[0].ErrorCode);
                            let errorText = data.Results[0].ErrorText;
                            if (errorCode === 0) {
                                vm.truckValid = true;
                            } else {
                                vm.truckValid = false;
                                vm.msgHandler(errorText, 'error');
                            }
                        }
                        vm.progressHide();
                    })
                    .catch(error => {
                        console.error('Axios error on NHTSA VIN verification ==> ' + error);
                        vm.errorHandler(error);
                    });
            } else {
                vm.truckValid = false;
            }
        }
    },
    mounted() {
        let vm = this;
        vm.getCustomers();
        vm.getIedacuList();
        vm.truckValid = false;
    },
    methods: {
        resetForm: function() {
            const vm = this;
            vm.customer = '';
            vm.vin = null;
            vm.truckName = '';
            vm.serialNo = '';
        },
        isNull: function(o) {
            return o === null;
        },
        isNullOrUndefined: function(o) {
            return o === null || o === undefined;
        },
        metersToFeet: function(meters) {
            return _.round(meters * 3.2808, 2);
        },
        sleep: function(timer) {
            return new Promise(resolve => setTimeout(resolve, timer));
        },
        progressShow: async function() {
            let vm = this;
            vm.overlay = true;
        },
        progressHide: async function() {
            let vm = this;
            vm.overlay = false;
        },
        errorHandler: function(errorObj) {
            let vm = this;
            let errorDesc;
            if (!vm.isNullOrUndefined(errorObj.response)) {
                errorDesc = JSON.stringify(errorObj.response.data);
            } else {
                errorDesc = errorObj.message;
            }
            vm.alertCopy = errorObj + ' - ' + errorDesc;
            vm.alertType = 'error';
            vm.progressHide();
            vm.showProgress = false;
            vm.showAlert = true;
            setTimeout(function() {
                vm.showAlert = false;
                vm.alertCopy = 'Currently showing no data...';
            }, 15000);
        },
        msgHandler: function(message, color) {
            const vm = this;
            vm.alertCopy = message;
            vm.alertType = color;
            vm.showAlert = true;
            setTimeout(function() {
                vm.showAlert = false;
            }, 15000);
        },
        getSchema: function() {
            const vm = this;
            const testName = vm.customer.toLowerCase();
            let dataSet = _.find(vm.companyList, function(o) {
                let match = o.company_name.toLowerCase();
                return match === testName;
            });
            if (!vm.isNullOrUndefined(dataSet)) {
                return dataSet.company_schema_id;
            }
            return '';
        },
        truckFilter: function() {
            const vm = this;
            vm.truckName = vm.truckName.replace(/[^a-zA-Z\d\- ]/g, '');
            vm.serialNo = vm.serialNo.replace(/[^a-zA-Z\d]/g, '').toUpperCase();
            vm.vin = vm.vin.replace(/[^a-zA-Z\d]/g, '').toUpperCase();

            if (vm.vin.length === 17 && vm.truckValid) {
                return true;
            } else {
                vm.msgHandler('Truck form validation failed. Please check values and try again.', 'warning');
            }
            return false;
        },
        truckSubmit: function() {
            const vm = this;
            let truckConfig = {};
            const tester = vm.truckFilter();
            if (!vm.truckName || !vm.serialNo || !vm.vin || !vm.customer) {
                vm.truckValid = false;
                return;
            }
            if (!tester) {
                return false;
            }
            truckConfig.schema_name = vm.getSchema();
            truckConfig.company = vm.customer;
            truckConfig.unit_name = vm.truckName;
            truckConfig.vin = vm.vin;
            truckConfig.serno = vm.serialNo;

            vm.registerTruck(truckConfig);
        },
        getIedacuList: async function() {
            const vm = this;
            let tmpData = [];
            let query = vm.queryPath + 'iedacu_list';
            await axios
                .get(query, vm.authConfig)
                .then(response => {
                    const resLength = response.data.length;
                    if (resLength > 0) {
                        const len = response.data.length;
                        _.forEach(response.data, function(o) {
                            let addObj = {};
                            addObj.schema_id = o.company_schema_id;
                            if (!vm.isNullOrUndefined(o.truck_info)) {
                                addObj.truck_name = o.truck_info['name'];
                            } else {
                                addObj.truck_name = 'Unknown';
                            }
                            addObj.vin = o.vin;
                            addObj.iedacu_id = o.iedacu_id;
                            addObj.serno = o.serial_number;
                            addObj.created_time = o.created_time;
                            tmpData.push(addObj);
                        });
                        vm.iedacuList = _.orderBy(tmpData, ['created_time'], ['desc']);
                        for (let i = 0; i < len; i++) {
                            vm.iedacuList[i].id = i + 1;
                        }
                        // console.info(vm.iedacuList);
                    } else {
                        console.log('Response array for iedacu list is empty...');
                    }
                })
                .catch(error => {
                    console.error('Axios error on getting iedacu list ==> ' + error);
                    vm.errorHandler(error);
                });
        },
        refreshList: async function () {
            await this.getIedacuList();
        },
        getCustomers: async function() {
            let vm = this;
            let query = vm.queryPath + 'get_customers';
            await axios
                .get(query, vm.authConfig)
                .then(response => {
                    const resLength = response.data.length;
                    if (resLength > 0) {
                        vm.companyItems = [];
                        vm.companyList = response.data.slice();
                        _.forEach(response.data, function(o) {
                            vm.companyItems.push(o.company_name);
                        });
                        vm.companyItems = _.sortBy(vm.companyItems, function(o) {
                            return o;
                        });
                    } else {
                        console.log('Response array for company names is empty...');
                    }
                })
                .catch(error => {
                    console.error('Axios error on getting company list ==> ' + error);
                    vm.errorHandler(error);
                });
        },
        registerTruck: async function(truckConfig) {
            let vm = this;
            let query = vm.queryPath + 'register_unit';
            await axios
                .post(query, truckConfig, vm.authConfig)
                .then(response => {
                    vm.msgHandler(response.data, 'info');
                    vm.resetForm();
                    _.delay(vm.refreshList, 3600);
                })
                .catch(error => {
                    console.error('Axios error on registering unit/truck ==> ' + error);
                    vm.errorHandler(error);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.overlayProgress {
    position: absolute;
    display: block;
    font-size: 27px;
    font-weight: bold;
    top: 56%;
    margin: 0 auto;
    width: 333px;
}

.page-title {
    display: inline-block;
    position: relative;
    margin-top: 16px;
    margin-left: 24px;
    color: #ffffff;
    font-size: 1.1rem;
    /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
    line-height: 1;
}
.show-selectors {
    display: block;
    position: relative;
    float: right;
    margin-top: 9px;
    margin-right: 9px;
}
#map {
    /* vertical-align: top; */
    display: block;
    position: relative;
    width: 100%;
    height: 640px;
}
.page-title {
    display: inline-block;
    position: relative;
    left: 40px;
    top: -30px;
    padding-top: 25px;
    color: white;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
}

.show-selectors {
    display: block;
    position: relative;
    float: right;
    margin-top: 24px;
    margin-right: 24px;
}

.data {
    margin-top: 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
h3 {
    margin-top: 40px;
    text-align: left;
    font-size: 36px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 10px;
}

table.v-table tbody td,
table.v-table thead th {
    min-width: 3%;
}

@media screen and (max-width: 750px) {
    .page-title {
        font-size: 18px;
    }

    h3 {
        text-align: center;
        font-size: 22px;
    }
    table.v-table tbody td {
        font-weight: 400;
        font-size: 9px;
    }
    table.v-table thead th {
        font-weight: 500;
        font-size: 9px;
    }
}
</style>
