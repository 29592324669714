<!--suppress JSUnresolvedVariable -->
<template>
    <div id="app" class="data">
        <v-app id="inspire" dark>
            <div>
                <v-alert :value="showAlert" type="info">{{ alertCopy }}</v-alert>
            </div>
            <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-container grid-list-md>
                <v-layout row wrap>
                    <!-- START User Registration Form -->
                    <v-flex xs12 lg3></v-flex>
                    <v-flex xs12 lg6>
                        <v-form ref="userForm" v-model="userValid" lazy-validation>
                            <v-toolbar-title>User Registration</v-toolbar-title>
                            <v-text-field v-model="userName" :counter="50" :rules="nameRules" label="Add Customer" required></v-text-field>
                            <v-col cols="12" class="d-flex justify-center align-center">
                                <v-btn :disabled="!userValid" color="success" class="mr-4" @click="checkCompanyName()">
                                    Submit
                                </v-btn>
                                <v-btn color="error" class="mr-4" @click="resetUserForm()">
                                    Reset
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-flex>
                    <v-flex xs12 lg3></v-flex>
                    <!-- END User Registration Form -->
                </v-layout>
                <!-- This is for the Customer List Display -->
                <v-layout row wrap>
                    <v-flex lg12>
                        <v-data-table
                            :headers="customerHeaders"
                            :items="companyItems"
                            :items-per-page="50"
                            class="elevation-1"
                        ></v-data-table>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-app>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import dotenv from 'dotenv';
dotenv.config();

export default {
    name: 'DataView',
    props: {
        msg: String
    },
    components: {},
    data: function() {
        return {
            customerHeaders: [{ text: 'Company', value: 'name', sortable: true }],
            userValid: true,
            customers: [],
            userName: '',
            userEmail: '',
            nameRules: [v => !!v || 'Name is required', v => (v && v.length > 2) || 'Name must be greater than 2 characters'],
            companyList: [],
            companyItems: [],
            showHelpModal: false,
            absolute: false,
            opacity: 0.85,
            overlay: false,
            showAlert: false,
            alertCopy: 'Currently showing no data...',
            queryPath: process.env.VUE_APP_API_PATH + '/registration/',
            queryPathBase: process.env.VUE_APP_API_PATH,
            lorem: 'Lorem ipsum dolor sit amet, mel at clita quando.',
            showProgress: false,
            showSelectors: false
        };
    },
    created() {
        this.$vuetify.theme.dark = true;
    },
    watch: {
        userValid: function() {
            const vm = this;
            if (!vm.userName || !vm.userEmail) {
                vm.userValid = false;
            }
        },
        userName: function (val) {
            const vm = this;
            vm.userName = vm.filterString(val);
        }
    },
    mounted() {
        const vm = this;
        vm.generateEmail();
        vm.getCompanyList();
        vm.userValid = false;
    },
    methods: {
        resetUserForm: function() {
            const vm = this;
            vm.userName = '';
            vm.generateEmail();
        },
        isNull: function(o) {
            return o === null;
        },
        isNullOrUndefined: function(o) {
            return o === null || o === undefined;
        },
        sleep: function(timer) {
            return new Promise(resolve => setTimeout(resolve, timer));
        },
        filterString: function (badStr) {
            return badStr.replace(/[^a-zA-Z0-9. ]/g, '');
        },
        progressShow: async function() {
            const vm = this;
            vm.overlay = true;
        },
        progressHide: async function() {
            const vm = this;
            vm.overlay = false;
        },
        msgHandler: function(message, color) {
            const vm = this;
            vm.alertCopy = message;
            vm.alertType = color;
            vm.showAlert = true;
            setTimeout(function() {
                vm.showAlert = false;
            }, 15000);
        },
        errorHandler: function(errorObj) {
            const vm = this;
            let errorDesc;
            if (!vm.isNullOrUndefined(errorObj.response)) {
                errorDesc = JSON.stringify(errorObj.response.data);
            } else {
                errorDesc = errorObj.message;
            }
            vm.alertCopy = errorObj + ' - ' + errorDesc;
            vm.alertType = 'error';
            vm.progressOverlay = false;
            vm.showProgress = false;
            vm.showAlert = true;
            setTimeout(function() {
                vm.showAlert = false;
                vm.alertCopy = 'Currently showing no data...';
            }, 9000);
        },
        generateEmail: function () {
            const email_append = Math.round(Math.random() * (90000 - 1) + 1);
            this.userEmail = `customer_${email_append}@hyliion.com`;
        },
        getCompanyList: async function() {
            const vm = this;
            let query = vm.queryPathBase + '/company';
            // console.info(query);
            await axios
                .get(query, vm.authConfig)
                .then(response => {
                    const resLength = response.data.length;
                    if (resLength > 0) {
                        vm.companyItems = [];
                        vm.companyList = _.orderBy(response.data, ['id'], ['desc'])
                        _.forEach(vm.companyList, function(o) {
                            vm.companyItems.push({ name: o.name });
                        });
                        // console.info(vm.companyList);
                    } else {
                        console.log('Response array for company names is empty...');
                    }
                })
                .catch(error => {
                    console.error('Axios error on getting company list ==> ' + error);
                    vm.errorHandler(error);
                });
        },
        checkCompanyName: function() {
            const vm = this;
            if (!vm.userName || !vm.userEmail) {
                vm.userValid = false;
                return;
            }
            let testName = vm.userName.toLowerCase();
            let customer = _.find(vm.companyItems, function(o) {
                let item = o.name.toLowerCase();
                return item === testName;
            });
            if (vm.isNullOrUndefined(customer)) {
                vm.addCustomer();
            } else {
                console.warn('Duplicate company name found, exiting...');
                vm.errorHandler('Duplicate company name found, exiting...');
            }
        },
        addCustomer: async function() {
            const vm = this;
            const config = {
                customer_name: vm.userName,
                customer_email: vm.userEmail
            };
            let query = vm.queryPath + 'create_customer';
            // console.log(query);
            // console.log(config);
            await axios
                .post(query, config)
                .then(response => {
                    // Iterate through tests data and map
                    vm.msgHandler(JSON.stringify(response.data), 'info');
                    vm.resetUserForm();
                })
                .catch(error => {
                    console.error('Axios error on getting tests ==> ' + error);
                    vm.errorHandler(error);
                });
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.overlayProgress {
    position: absolute;
    display: block;
    font-size: 27px;
    font-weight: bold;
    top: 56%;
    margin: 0 auto;
    width: 333px;
}

.page-title {
    display: inline-block;
    position: relative;
    margin-top: 16px;
    margin-left: 24px;
    color: #ffffff;
    font-size: 1.1rem;
    /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
    line-height: 1;
}
.show-selectors {
    display: block;
    position: relative;
    float: right;
    margin-top: 9px;
    margin-right: 9px;
}
#map {
    /* vertical-align: top; */
    display: block;
    position: relative;
    width: 100%;
    height: 640px;
}
.page-title {
    display: inline-block;
    position: relative;
    left: 40px;
    top: -30px;
    padding-top: 25px;
    color: white;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
}

.show-selectors {
    display: block;
    position: relative;
    float: right;
    margin-top: 24px;
    margin-right: 24px;
}

.data {
    margin-top: 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
h3 {
    margin-top: 40px;
    text-align: left;
    font-size: 36px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 10px;
}

table.v-table tbody td,
table.v-table thead th {
    min-width: 3%;
}

@media screen and (max-width: 750px) {
    .page-title {
        font-size: 18px;
    }

    h3 {
        text-align: center;
        font-size: 22px;
    }
    table.v-table tbody td {
        font-weight: 400;
        font-size: 9px;
    }
    table.v-table thead th {
        font-weight: 500;
        font-size: 9px;
    }
}
</style>
