<template>
    <v-app v-if="authenticated">
        <v-navigation-drawer v-model="drawer" app clipped>
            <v-list dense>
                <v-list-item @click="showArea('showTruckRegistration')">
                    <v-list-item-action>
                        <v-icon>create</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Truck Registration</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="showArea('showUserRegistration')">
                    <v-list-item-action>
                        <v-icon>add</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>User Registration</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar style="background: #43b02a; height: 70px" app clipped-left>
            <img
                style="padding: 10px; left: 6px; position: relative; padding-right: 32px; margin-left: 0"
                src="@/assets/hyliiion_logo_with_text.png"
                title="v 1.0.5"
                @click.stop="drawer = !drawer"
            />
            <v-toolbar-title>
                <span>Customer/Unit Registration Tool</span>
            </v-toolbar-title>
        </v-app-bar>

        <v-content v-if="currentSection.type == 'showUserRegistration'" :key="changeKey">
            <UserView />
        </v-content>

        <v-content v-if="currentSection.type == 'showTruckRegistration'">
            <TruckView />
        </v-content>

        <v-footer app>
            <span>&copy; Hyliion Inc. {{ currentYear }}</span>
        </v-footer>
    </v-app>
</template>

<script>
import UserView from './components/Userview.vue'
import TruckView from './components/Truckview.vue'
import axios from 'axios';

export default {
    name: 'App',
    components: {
        TruckView, UserView
    },
    data: () => ({
        drawer: true,
        currentSection: { type: 'showTruckRegistration' },
        currentYear: new Date().getFullYear(),
        isInvalidKey: false,
        changeKey: 1,
        authenticated: false,
    }),
    async mounted() {
        let vm = this;
        vm.authenticate();
        axios.interceptors.request.use(
            // Pre-request handler
            async req => {
                // Determine if request is outbound to Hyliion APIs or local dev server
                const isHyliionRequest =
                    req.url.includes('hyliion.com') ||
                    req.url.includes('localhost') ||
                    req.url.includes('127.0.0.1');
                if (isHyliionRequest) {
                    const token = await this.getToken().catch(async () => {
                        await vm.authenticate();
                    });
                    req.headers.authorization = `Bearer ${token}`;
                }
                return req;
            },
            // Error handler
            function(err) {
                console.log(err);
            }
        );
    },
    methods: {
        isNull: function(o) {
            return o === null;
        },
        isNullOrUndefined: function(o) {
            return o === null || o === undefined;
        },
        showArea: function (areaStr) {
            let vm = this;
            vm.currentSection.type = areaStr;
        },
        getToken: async function () {
            let vm = this;
            let msalInstance = vm.$msal;
            let account = msalInstance.getAllAccounts()[0];
            if (!account) {
                await vm.authenticate()
            }
            const tokenResponse = await msalInstance.getSilentToken(account, msalInstance.config.auth.scopes);
            if(!tokenResponse || (tokenResponse && tokenResponse.accessToken)) {
                await vm.authenticate();
            }
            return tokenResponse.accessToken;
        },
        authenticate: async function() {
            const vm = this;
            const msalInstance = vm.$msal;
            try {
                await msalInstance.authenticate();
                vm.authenticated = true;
            } catch (err) {
                // handle error
                console.log("msal error: ", err);
            }
        },
    },
};
</script>

<style scoped>
.app-bar {
    background-color: #42b983;
}
.dark-adjust {
    background-color: #303030;
}
.page-title {
    display: inline-block;
    position: relative;
    left: 40px;
    top: -30px;
    padding-top: 25px;
    color: white;
    font-size: 24px;
    font-family: Roboto;
}
.row-title {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
}
</style>
